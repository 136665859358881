const plans = [
  {
    id: 1,
    name: 'Default Plan',
    assigned_employees: 4,
    effective_date: '2022-02-01',
    schedule: [
      {
        work_plan_schedule: 'Work period',
        sun: {
          start: '10:00',
          end: '18:00',
        },
        mon: {
          start: '10:00',
          end: '18:00',
        },
        tue: {
          start: '10:00',
          end: '18:00',
        },
        wed: {
          start: '10:00',
          end: '18:00',
        },
        thu: {
          start: '10:00',
          end: '18:00',
        },
        fri: {},
        sat: {},
      },
      {
        work_plan_schedule: 'Flexible Time',
        sun: {
          start: 15,
          end: 0,
        },
        mon: {
          start: 15,
          end: 0,
        },
        tue: {
          start: 15,
          end: 0,
        },
        wed: {
          start: 15,
          end: 0,
        },
        thu: {
          start: 15,
          end: 0,
        },
        fri: {},
        sat: {},
      },
    ],
    rules: {
      late_attendance: [
        {
          from: 15,
          to: 60,
          penalties: [
            {
              time: 1,
              rate: 0,
            },
            {
              time: 2,
              rate: 0,
            },
            {
              time: 3,
              rate: 2,
            },
            {
              time: 4,
              rate: 2,
            },
            {
              time: 5,
              rate: 4,
            },
          ],
        },
        {
          from: 60,
          to: 120,
          penalties: [
            {
              time: 1,
              rate: 4,
            },
            {
              time: 2,
              rate: 4,
            },
            {
              time: 3,
              rate: 8,
            },
            {
              time: 4,
              rate: 8,
            },
            {
              time: 5,
              rate: 16,
            },
          ],
        },
        {
          from: 120,
          to: null,
          penalties: [
            {
              time: 1,
              rate: 4,
            },
            {
              time: 2,
              rate: 4,
            },
            {
              time: 3,
              rate: 8,
            },
            {
              time: 4,
              rate: 8,
            },
            {
              time: 5,
              rate: 16,
            },
          ],
        },
      ],
      early_leave: [
        {
          from: 15,
          to: 60,
          penalties: [
            {
              time: 1,
              rate: 0,
            },
            {
              time: 2,
              rate: 0,
            },
            {
              time: 3,
              rate: 2,
            },
            {
              time: 4,
              rate: 2,
            },
            {
              time: 5,
              rate: 4,
            },
          ],
        },
        {
          from: 60,
          to: null,
          penalties: [
            {
              time: 1,
              rate: 4,
            },
            {
              time: 2,
              rate: 4,
            },
            {
              time: 3,
              rate: 8,
            },
            {
              time: 4,
              rate: 8,
            },
            {
              time: 5,
              rate: 16,
            },
          ],
        },
      ],
      absence_days: [
        {
          time: 1,
          rate: 4,
        },
        {
          time: 2,
          rate: 4,
        },
        {
          time: 3,
          rate: 8,
        },
        {
          time: 4,
          rate: 16,
        },
        {
          time: 5,
          rate: 24,
        },
      ],
      extra_missing_hours: {
        calculated: 'daily',
        calculated_by: 'hours',
        extra_hours: {
          max: 8,
          rules: [
            {
              from: 1,
              to: 2,
              hour_rate: 1.5,
            },
            {
              from: 2,
              to: '',
              hour_rate: 1.75,
            },
          ],
        },
        missing_hours: {
          max: 8,
          rules: [
            {
              from: 1,
              to: '',
              hour_rate: 1.5,
            },
          ],
        },
      },
      off_days: [
        {
          time: 'holiday',
          hour_rate: 2,
        },
        {
          time: 'weekend',
          hour_rate: 1.5,
        },
        {
          time: 'vacation',
          hour_rate: 1,
        },
      ],
    },
  },
  {
    id: 2,
    name: 'Part Time',
    assigned_employees: 2,
    effective_date: '2022-02-01',
    schedule: [
      {
        work_plan_schedule: 'Work period',
        sun: {
          start: '10:00',
          end: '18:00',
        },
        mon: {},
        tue: {
          start: '10:00',
          end: '18:00',
        },
        wed: {},
        thu: {
          start: '10:00',
          end: '18:00',
        },
        fri: {},
        sat: {},
      },
      {
        work_plan_schedule: 'Flexible Time',
        sun: {
          start: 15,
          end: 15,
        },
        mon: {},
        tue: {
          start: 15,
          end: 15,
        },
        wed: {},
        thu: {
          start: 15,
          end: 15,
        },
        fri: {},
        sat: {},
      },
    ],
    rules: {
      late_attendance: [
        {
          from: 15,
          to: null,
          penalties: [
            {
              time: 1,
              rate: 0,
            },
            {
              time: 2,
              rate: 2,
            },
            {
              time: 3,
              rate: 4,
            },
          ],
        },
      ],
      early_leave: [
        {
          from: 15,
          to: null,
          penalties: [
            {
              time: 1,
              rate: 0,
            },
            {
              time: 2,
              rate: 2,
            },
            {
              time: 3,
              rate: 4,
            },
          ],
        },
      ],
      off_days: [
        {
          time: 'holiday',
          hour_rate: 2,
        },
        {
          time: 'weekend',
          hour_rate: 1.5,
        },
        {
          time: 'vacation',
          hour_rate: 1,
        },
      ],
      absence_days: [
        {
          time: 1,
          rate: 4,
        },
        {
          time: 2,
          rate: 8,
        },
        {
          time: 3,
          rate: 16,
        },
      ],
    },
  },
]

export { plans }
