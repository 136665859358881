<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Plans from "./plans.vue";

import { plans } from "./entries";
import { workPlanMethods } from "../../../state/helpers";
import { mapGetters } from "vuex";

/**
 * Work Plans component
 */
export default {
  page: {
    title: "Work Plans",
  },
  components: {
    Layout,
    PageHeader,
    Plans,
  },
  data() {
    return {
      plans,
      title: "Work Plans",
      items: [
        {
          text: "HR",
          href: "/",
        },
        {
          text: "Work Plans",
          active: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("workPlans", ["work_plans"]),
  },
  mounted() {},
  methods: {
    ...workPlanMethods,
  },
  created() {
    this.getWorkPlans();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-card>
      <Plans :plans="work_plans" />
    </b-card>
    <!-- <b-tabs content-class="mt-3" fill pills>
      <b-tab title="Daily Oriented" active>
      </b-tab>
      <b-tab title="Task Oriented"> </b-tab>
      <b-tab title="Shift Oriented"> </b-tab>
    </b-tabs> -->
  </Layout>
</template>