<script>
/**
 * Plans component
 */
export default {
  props: {
    plans: {
      default: [],
    },
    showPagination: {
      default: true,
      type: Boolean,
    },
    showFilter: {
      default: true,
      type: Boolean,
    },
    perPage: {
      default: 10,
      type: Number,
    },
  },
  page: {},
  components: {},
  data() {
    return {
      totalRows: 1,
      currentPage: 1,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "start_date",
      sortDesc: true,
      fields: [{ key: "name", sortable: true }, { key: "action" }],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.plans.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.plans.length;
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onRowSelected(row) {
      console.log(row);
    },
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div v-if="showPagination" class="col-sm-12 col-md-6">
        <div id="tickets-table_length" class="dataTables_length">
          <label class="d-inline-flex align-items-center">
            Show&nbsp;
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            ></b-form-select
            >&nbsp;entries
          </label>
        </div>
      </div>
      <!-- Search -->
      <div v-if="showFilter" class="col-sm-12 col-md-6">
        <div id="tickets-table_filter" class="dataTables_filter text-md-right">
          <label class="d-inline-flex align-items-center">
            Search:
            <b-form-input
              v-model="filter"
              type="search"
              placeholder="Search..."
              class="form-control form-control-sm ml-2"
            ></b-form-input>
          </label>
          <b-button href="/work_plans/create" class="mx-2" size="sm"
            ><i class="mdi mdi-plus"></i
          ></b-button>
        </div>
      </div>
      <!-- End search -->
    </div>
    <div class="table-responsive">
      <b-table
        hover
        striped
        :fields="fields"
        :items="plans"
        responsive="sm"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(action)="row">
          <b-btn-group>
            <b-button variant="link" :href="`/work_plans/${row.item.id}`">
              <i class="fa fa-eye text-info"></i>
            </b-button>
          </b-btn-group>
        </template>
        <template #cell()="row">
          <div class="text-nowrap text-capitalize">
            {{ row.value || "---" }}
          </div>
        </template>
      </b-table>
    </div>
    <b-row>
      <b-col>
        <div
          v-if="showPagination"
          class="dataTables_paginate paging_simple_numbers float-right"
        >
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
            ></b-pagination>
          </ul>
        </div>
      </b-col>
    </b-row>
  </div>
</template>